export class Common{
    public static removeUrlParam(key:string):void {
        let url = window.location.href;
        var rtn = url.split("?")[0],
            param,
            params_arr = [],
            queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }

        if(rtn.endsWith("?")){
            rtn = rtn.substr(0, rtn.length-1);
        }

        window.history.pushState(null, null, rtn); //remove request id in url
    }

    public static changeUrlWithoutReload(newUrl: string):void{
        window.history.pushState(null, null, newUrl);
    }

    public static getCurrentServerUrl(){
        let port = window.location.port && window.location.port!="0" && window.location.port!="80" && window.location.port!="443" ? `:${window.location.port}` : "";
        return `${window.location.protocol}//${window.location.hostname}${port}`.toLowerCase();
    }
}