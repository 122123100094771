import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, Link, Spinner } from 'office-ui-fabric-react';

export class CookieList extends React.Component {

    public render() {
        return (
            <div>
                <div id="ot-sdk-cookie-policy"></div>
            </div>
        );
    }
}